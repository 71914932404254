import React from 'react';

import { Container, H1, Seo } from '../../components';

const PrivacyPolicy: React.FC = React.memo(() => (
  <>
    <Seo />
    <Container>
      <H1 margin="3.5rem 0" textAlign="center">
        Privacy Policy
      </H1>
    </Container>
  </>
));

PrivacyPolicy.displayName = 'Privacy policy';

export default PrivacyPolicy;
